<template>
  <div>
    <b-container>
      <b-row class="not-found-row" align-h="center" align-v="center">
        <b-col cols="auto">
          <p class="not-found-text">404 Error - Не найдено</p>
          <button
            type="button"
            @click="
              () => {
                $router.go(-1);
              }
            "
            class="btn btn-md btn-block btn-black"
          >
            Назад
          </button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "NotFoundView",
};
</script>

<style lang="scss">
.not-found-row {
  height: 100vh;
}
.not-found-text {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 15px;
}
</style>
