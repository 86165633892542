import axios from "axios";

export default {
  state: {
    all: [],
    view: null,
  },
  getters: {},
  mutations: {
    SET_RELEASES(state, payload) {
      state.all = payload;
    },
  },
  actions: {
    async getReleases({ commit }) {
      let releases = await axios.get("releases");
      releases = releases.data.data;

      commit("SET_RELEASES", releases);
    },
  },
  modules: {},
};
