import Vue from "vue";
import VueRouter from "vue-router";
import MainView from "../views/Work/WorksView.vue";
import NotFoundView from "../views/NotFoundView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: MainView,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFoundView,
  },
  {
    path: "/delivery",
    name: "delivery",
    component: () => import("../views/DeliveryView.vue"),
  },

  // Releases
  {
    path: "/releases",
    name: "releases",
    component: () => import("../views/Release/ReleasesView.vue"),
  },
  {
    path: "/releases/:code",
    name: "release",
    component: () => import("../views/Release/ReleaseDetailView.vue"),
  },
  // Works
  {
    path: "/works",
    name: "works",
    component: () => import("../views/Work/WorksView.vue"),
  },
  {
    path: "/works/:id",
    name: "works.detail",
    component: () => import("../views/Work/WorkDetailView.vue"),
  },
  {
    path: "/works/:workId/order/",
    name: "works.order",
    component: () => import("../views/Work/WorkOrderView.vue"),
  },
  // blog
  {
    path: "/why",
    name: "why",
    component: () => import("../views/WhyView.vue"),
  },
  {
    path: "/art-is",
    name: "art-is",
    component: () => import("../views/BuyArtFast.vue"),
  },
  {
    path: "/abstract-painting",
    name: "abstract-painting",
    component: () => import("../views/AbstractPaintingView.vue"),
  },
  // Admin
  {
    path: "/admin/works",
    name: "admin.works",
    component: () => import("../views/admin/AdminWorksView.vue"),
  },
  {
    path: "/admin/works/:id",
    name: "admin.works.detail",
    component: () => import("../views/admin/AdminWorkDetailView.vue"),
  },
];

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

export default router;
