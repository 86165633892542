import axios from "axios";

export default {
  state: {
    all: [],
    view: null,
  },
  getters: {},
  mutations: {
    SET_ALL(state, payload) {
      state.all = payload;
    },
  },
  actions: {
    async getServices({ commit }) {
      let res = await axios.get("services");
      res = res.data;

      commit("SET_ALL", res);
    },
  },
  modules: {},
};
