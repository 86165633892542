<template>
  <div class="footer">
    <b-container>
      <b-row class="footer-wrapper" align-h="between" align-v="start">
        <b-col cols="12" md="auto">
          <p class="footer-author">
            © {{ new Date().getFullYear() }} - elloston
          </p>
        </b-col>

        <b-col cols="12" md="auto">
          <div class="footer-contacts" id="contacts">
            <a
              href="mailto: elloston@icloud.com"
              target="_blank"
              class="footer-email"
              ><span class="footer-description-span">Email: </span
              >elloston@icloud.com</a
            >

            <a href="tel: +7 999 445 84-27" target="_blank" class="footer-email"
              ><span class="footer-description-span">Телефон: </span>+7 999 445
              84-27</a
            >
          </div>
        </b-col>

        <b-col cols="12" md="auto">
          <div class="footer-rq-block">
            <p class="footer-rq">ИП Перепелица Вячеслав Евгеньевич</p>

            <p class="footer-rq">
              <span class="footer-description-span">ИНН: </span>241102832882
            </p>

            <p class="footer-rq">
              <span class="footer-description-span">ОГРН: </span>321246800107212
            </p>
          </div>
        </b-col>

        <b-col cols="12" md="auto">
          <div class="social-links">
            <a
              class="social-link"
              href="https://www.instagram.com/elloston"
              target="_blank"
              ><img
                class="social-link-img"
                :src="require('@/assets/svg/social-icons/instagram.svg')"
                alt="instagram"
            /></a>

            <a
              class="social-link"
              href="https://vk.com/elloston_community"
              target="_blank"
              ><img
                class="social-link-img"
                :src="require('@/assets/svg/social-icons/vk.svg')"
                alt="vk"
            /></a>

            <a
              class="social-link"
              href="https://www.youtube.com/channel/UCLVJFz7pGZz3asmiWd1XUrA"
              target="_blank"
              ><img
                class="social-link-img"
                :src="require('@/assets/svg/social-icons/youtube.svg')"
                alt="youtube"
            /></a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
.footer {
  position: relative;
  z-index: 10;
  background-color: $white;
  color: $zinc900;

  border-top: 1px solid;
  border-top-color: $zinc200;

  padding-top: 100px;
  padding-bottom: 100px;
}
.footer-wrapper {
  text-align: start;
  font-family: "Montserrat", sans-serif;
}
.footer-description-span {
  text-transform: uppercase;
  font-size: 10px;
  color: rgba($zinc700, 1);

  text-decoration: none;
}

.social-links {
  display: flex;
}
.social-link {
  position: relative;
  height: 32px;
  width: 32px;
  display: block;
  margin-right: 10px;
}
.social-link-img {
  height: 100%;
  width: 100%;
}

.footer-contacts {
  margin-bottom: 30px;
}
.footer-author {
  cursor: pointer;

  margin-bottom: 0;

  color: $zinc500;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.footer-author:hover {
  color: $zinc600;
}
.footer-email {
  display: block;
  color: $zinc500;

  font-size: 14px;
  font-weight: 500;
}
.footer-email:hover {
  text-decoration: none;
  color: $zinc600;
}

.footer-rq-block {
  font-size: 14px;
  margin-bottom: 30px;
}
.footer-rq {
  color: $zinc500;
  font-size: 14px;

  text-transform: uppercase;
  font-weight: 500;
}

.dev-link-text {
  display: block;

  padding: 0;
  margin: 0;
}
.dev-link-text:hover {
  color: $zinc400;
}

@media only screen and (max-width: 640px) {
  .social-links {
    margin-top: 30px;
  }

  .footer-author {
    margin-bottom: 30px;
  }
}
</style>
