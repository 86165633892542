import Vue from 'vue';
import Vuex from 'vuex';
import release from '../store/release.js';
import service from '../store/service.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // urlApi: 'http://127.0.0.1:8000/'

    apiUrl: `https://api.elloston.com/public/api/`,
    storeUrl: `https://api.elloston.com/storage/app/public/`,

    loading: false,

    background: null,
  },
  getters: {},
  mutations: {
    LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {},
  modules: {
    release,
    service,
  },
});
