<template>
  <nav class="main-nav">
    <!-- Desktop -->
    <b-container class="d-none d-lg-block">
      <b-row align-h="start" align-v="center">
        <b-col cols="auto">
          <div class="main-nav-logo">
            <img :src="require(`@/assets/logo.png`)" alt="" />
          </div>
        </b-col>

        <b-col cols="auto" class="p-0">
          <div class="main-nav-links">
            <router-link
              v-for="(link, index) in links"
              :key="index"
              class="main-nav-link"
              :to="link.route"
              >{{ link.label }}</router-link
            >
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Mobile -->
    <b-container class="d-lg-none">
      <b-row align-h="between">
        <b-col cols="auto">
          <div class="burger-menu-wrapper">
            <button class="burger-menu" v-b-toggle.mobileMenu>
              <div class="burger-menu-line"></div>
              <div class="burger-menu-line"></div>
              <div class="burger-menu-line"></div>
            </button>
          </div>
        </b-col>

        <b-col cols="auto">
          <router-link to="/">
            <div class="main-nav-logo">
              <img
                class="main-nav-logo__img"
                :src="require(`@/assets/logo.png`)"
                alt=""
              />
            </div>
          </router-link>
        </b-col>
      </b-row>
    </b-container>

    <b-sidebar id="mobileMenu" shadow no-header backdrop #default="{ hide }">
      <b-container class="d-lg-none">
        <b-row align-h="center">
          <b-col cols="12">
            <button class="hide-sidebar-button" @click="hide">
              <img :src="require('@/assets/svg/menu/close_big.svg')" alt="" />
            </button>

            <div class="main-nav-links--mobile">
              <router-link
                :to="link.route"
                @click="hide"
                v-for="(link, index) in links"
                :key="index"
                class="main-nav-link--mobile"
                >{{ link.label }}</router-link
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  methods() {},
  data() {
    return {
      links: [
        // { label: "Home", route: "/" },
        { label: "Релизы", route: "/releases" },
        { label: "Картины", route: "/works" },
        { label: "Доставка и оплата", route: "/delivery" },
        { label: "Контакты", route: "#contacts" },
      ],
    };
  },
};
</script>

<style lang="scss">
.main-nav {
  position: sticky;
  z-index: 1000;
  top: 0;

  width: 100%;

  padding-top: 15px;
  padding-bottom: 15px;

  background-color: $white;
  border-bottom: 1px solid;
  border-color: $zinc200;
}

// logo
.main-nav-logo {
  height: 30px;
  width: 30px;
  position: relative;
  overflow: hidden;
}
.main-nav-logo__img {
  object-fit: contain;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// burger
.burger-menu-wrapper {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.burger-menu {
  width: 24px;
  height: auto;
}

.burger-menu-line {
  width: 100%;
  height: 1.5px;
  background-color: $zinc600;

  margin-bottom: 5px;
  margin-top: 5px;
}

.main-nav-links {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.main-nav-links--mobile {
  padding-top: 30px;
}

.hide-sidebar-button {
  font-size: 30px;
}

// main nav link
.main-nav-link {
  margin-left: 15px;

  color: rgba($zinc800, 0.9);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;

  transition: all ease-out 100ms;
}
.main-nav-link:hover {
  color: rgba($zinc800, 1);
}
.main-nav-link.router-link-exact-active,
.main-nav-link.router-link-active {
  color: rgba($zinc950, 1);
  font-weight: 500;
}

.main-nav-link--mobile:last-child {
  border-bottom: none;
}

// main nav link mobile
.main-nav-link--mobile {
  color: rgba($zinc800, 0.9);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;

  display: block;

  padding-top: 15px;
  padding-bottom: 15px;

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $zinc200;

  transition: all ease-out 100ms;
}
.main-nav-link--mobile:hover {
  color: rgba($zinc800, 1);
}
.main-nav-link--mobile.router-link-exact-active,
.main-nav-link--mobile.router-link-active {
  color: rgba($zinc950, 1);
  font-weight: 500;
}

@media only screen and (max-width: 640px) {
}
</style>
