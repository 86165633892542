<template>
  <div id="app">
    <navbar />

    <router-view />

    <Footer />
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: { Footer, Navbar },
};
</script>

<style lang="scss"></style>
