<template>
  <div class="works height-100">
    <b-container>
      <b-row>
        <b-col class="view-header" cols="12">
          <div v-if="works.length" class="comeback-link">
            Всего: {{ works.length }}
          </div>
        </b-col>
      </b-row>

      <b-row cols="1" cols-md="3">
        <b-col v-for="(work, index) in works" :key="index">
          <router-link class="work-link" :to="{ path: `/works/${work.id}` }">
            <div class="work-preview-link">
              <div class="work-preview">
                <div class="work-preview-box">
                  <img
                    v-if="work.images.length"
                    class="work-preview-box__img"
                    :src="`${$store.state.storeUrl}works/${work.images[0].url}`"
                    alt=""
                  />

                  <div class="work-preview__more">
                    <div class="work-preview__more-plus">+</div>
                  </div>
                </div>
              </div>

              <div class="work-preview-description">
                <p v-if="work.user_id == 1" class="work-preview-author">
                  elloston
                </p>

                <p v-if="work.user_id == 2" class="work-preview-author">
                  Ольга
                </p>

                <p class="work-preview-title" v-if="work.title">
                  {{ work.title }}
                </p>

                <p
                  v-if="!work.successfulOrder && work.starting_price"
                  class="work-preview-price"
                >
                  {{ price(work.starting_price) }}
                </p>

                <p v-if="work.successfulOrder" class="work-preview-price">
                  Продано
                </p>
              </div>
            </div>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "WorksView",
  data() {
    return {
      works: [],
    };
  },
  methods: {
    price(val) {
      return new Intl.NumberFormat("ru", {
        style: "currency",
        currency: "rub",
        maximumSignificantDigits: "20",
      }).format(val);
    },
  },
  async mounted() {
    try {
      this.$store.commit("LOADING", true);

      let works = await axios.get("works");
      this.works = works.data.data;

      this.$store.commit("LOADING", false);
    } catch (e) {
      this.$store.commit("LOADING", false);
      this.$router.replace({ name: "NotFound" });
    }
  },
};
</script>

<style lang="scss">
.works {
  padding-top: 30px;
  padding-bottom: 100px;
}

.work-link:hover {
  text-decoration: none;
}

.work-preview-link {
  margin-bottom: 30px;
}
.work-preview {
  position: relative;
  margin-bottom: 15px;
}
.work-preview-box {
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  background-color: $zinc100;
}
.work-preview-box__img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: rgba($color: $zinc50, $alpha: 1);
}
// preview text
.work-preview-author {
  color: $zinc800;

  text-align: start;
  font-weight: 600;
  font-size: 11px;

  padding-bottom: 5px;
}
.work-preview-description {
  padding-left: 15px;
}
.work-preview-title {
  color: $zinc900;
  text-align: start;

  font-weight: 400;
  font-style: italic;
  font-size: 12px;

  padding-bottom: 5px;
}
.work-preview-price {
  color: $zinc500;
  text-align: start;

  font-weight: 500;
  font-size: 10px;
}

.work-preview__more {
  height: 35px;
  width: 35px;
  background-color: $white;

  position: absolute;
  bottom: 0;
  right: 0;

  font-size: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.work-preview__more-plus {
  font-size: 28px;
  color: $zinc500;
  text-align: center;
  line-height: 28px;
  font-weight: 300;
}
</style>
